var animatinOnScroll = (function (){
	var jaAnimate = false;

	function isOnRect (){
		var windowHeight = window.innerHeight;

		$('[data-animate]').each(function () {
			var animation = $(this).data('animate');

			if(this.getBoundingClientRect().top <= (windowHeight - 50)){
				$(this).addClass(animation);
				$(this).removeAttr('data-animate');
			}
		});
	}

	$(window).scroll(function(){
		if(jaAnimate) return;

		setTimeout(function(){
			jaAnimate = false;
		}, 100)

		isOnRect();
	});

	isOnRect();
})();